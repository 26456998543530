import './style.css'
import * as THREE from 'three'
// import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';

import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass.js'
import { SMAAPass } from 'three/examples/jsm/postprocessing/SMAAPass.js'

import * as dat from 'dat.gui'
import { gsap } from 'gsap';
import { sRGBEncoding } from 'three';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass';

// DOM elements
const loadingBarElement = document.querySelector('.loading-bar');
const h1 = document.querySelector('h1');
const h2 = document.querySelector('h2');

// gsap
const introTimeLine = gsap.timeline();
const rotationTimeline = gsap.timeline({ 
    repeat: Infinity,
    yoyo: true,
});

const positionTimeline = gsap.timeline({ 
    repeat: Infinity,
    yoyo: true,
});

introTimeLine
            .to(h1, { delay: 1, duration: 2, clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0 100%)', y: '30px'})
            .to(h2, { duration: 3, clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0 100%)', y: '-30px'}, '-=1');

// Loaders
const loadingManager = new THREE.LoadingManager(
    () => {
        // loaded
        gsap.delayedCall(0.5, () => {
            gsap.to(overlayMaterial.uniforms.uAlpha, { duration: 3, value: 0});
            loadingBarElement.classList.add('ended');
            loadingBarElement.style.transform = '';
        });

    },
    (itemUrl, itemsLoaded, itemsTotal) => {
        // progress
        const progressRatio = itemsLoaded / itemsTotal;
        loadingBarElement.style.transform = `scaleX(${progressRatio})`;
    }, 
    () => {
        // error
    }
);

const textureLoader = new THREE.TextureLoader(loadingManager);
const gltfLoader = new GLTFLoader(loadingManager);
const dracoLoader = new DRACOLoader(loadingManager);
dracoLoader.setDecoderPath('/draco/');
gltfLoader.setDRACOLoader(dracoLoader);

// Debug
const gui = new dat.GUI({closed: true})
const debugObject = {
    fogColor: '#492e49',
    bgColor: '#160b14',
}

// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()

// Textures
const terrainTexture = textureLoader.load('/textures/terrain_baked_512_denoised.png');
const ellenTexture = textureLoader.load('/textures/ellen_baked_512_denoised.png');
const roverTexture = textureLoader.load('/textures/rover_baked_512_denoised.png');
const tentacleTexture = textureLoader.load('textures/tentacle_baked_512_denoised.png');

terrainTexture.flipY = false;
ellenTexture.flipY = false;
roverTexture.flipY = false;
tentacleTexture.flipY = false;

terrainTexture.encoding = sRGBEncoding;
ellenTexture.encoding = sRGBEncoding;
roverTexture.encoding = sRGBEncoding;
tentacleTexture.encoding = sRGBEncoding;

// Materials
const terrainMaterial = new THREE.MeshBasicMaterial({ map: terrainTexture });
const ellenMaterial = new THREE.MeshBasicMaterial({ map: ellenTexture });
const roverMaterial = new THREE.MeshBasicMaterial({ map: roverTexture });
const tentacleMaterial = new THREE.MeshBasicMaterial({ map: tentacleTexture });

const emissiveMaterial = new THREE.MeshStandardMaterial({ 
    color: 0xffffff,
    map: roverTexture,
    emissive: 0xffffff,
    emissiveIntensity: 1,
    metalness: 0.5,
    roughness: 0.5527864098548889,
});

const updateAllMaterials = () => {
    scene.traverse((child) => {
        if (child instanceof THREE.Mesh && child.material instanceof THREE.MeshStandardMaterial) {
            child.material.needsUpdate = true;
            child.castShadow = true;
            child.receiveShadow = true;
        }
    })
}

const overlayMaterial = new THREE.ShaderMaterial({
    transparent: true,
    uniforms: {
        uAlpha: { value: 1 }
    },
    vertexShader: `
        void main()
        {
            gl_Position = vec4(position, 1.0);
        }
    `,
    fragmentShader: `
    uniform float uAlpha;

        void main() {
            gl_FragColor = vec4(0.049, 0.027, 0.104, uAlpha);
        }
    `
})

const vignetteMaterial = {
    uniforms: {
    "tDiffuse": { value: null },
    "vignette": { value: 0.6 },
    "exposure": { value: 1.2 },
    "color": { value: new THREE.Color(0.85, 0.9, 1.0) }
    },

    vertexShader: [

        "varying vec2 vUv;",

        "void main() {",

            "vUv = uv;",
            "gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );",

        "}"

    ].join( "\n" ),

    fragmentShader: [

    "uniform sampler2D tDiffuse;",
    "uniform float vignette;",
    "uniform float exposure;",
    "uniform vec3 color;",
    "varying vec2 vUv;",

        "void main() {",
            "vec4 texel = texture2D( tDiffuse, vUv );",
            "vec2 p = vUv * 2.0 - 1.0;",
            "gl_FragColor = texel;",
            "gl_FragColor.xyz = pow(gl_FragColor.xyz*exposure, color);",
            "gl_FragColor.xyz *= clamp(1.0 - length(p) *vignette, 0.0, 1.0 );",
        "}"

    ].join( "\n" )
};

const overlayGeometry = new THREE.PlaneGeometry(2, 2, 1, 1)
const overlay = new THREE.Mesh(overlayGeometry, overlayMaterial)
scene.add(overlay);
scene.background = new THREE.Color(debugObject.fogColor);

// Fog
const fog = new THREE.Fog(debugObject.fogColor, 5, 23);
scene.fog = fog;
gui.add(fog, 'far', 0, 100);
gui.add(fog, 'near', 0, 100);
gui.addColor(debugObject, 'fogColor').onFinishChange(() => {
    const pickedColor = new THREE.Color(debugObject.fogColor);
    fog.color = pickedColor;
    scene.background = pickedColor;
});

debugObject.envMapIntensity = 1;
gui.add(debugObject, 'envMapIntensity').min(0).max(5).step(0.001).onChange(updateAllMaterials);

/**
 * Models
 */

let model;
let mixer;
let animations;
let idleAction;

let terrain, ellen, rover, drone, tentacle, sky;

gltfLoader.load(
    'models/scene.glb',
    gltf => {
        model = gltf.scene;
        scene.add(model);
        console.log('loaded');

        // model.scale.set(.2, .2, .2);
        // model.scale.set(9, 9, 9);

        model.position.set(1, 0, 0);
        model.rotation.set(0.45, 0.9, -0.2);
        rotationTimeline
            .to(model.rotation, {x: 0.52, y: 1.2, z: -0.7, duration: 20, ease: ' power1. inOut'})
            .to(model.rotation, {x: -0.17, y: 0.25, z: 0.25, duration: 20, ease: ' power1. inOut'})
            .to(model.rotation, {x: 0.45, y: 0.9, z: -0.2, duration: 20, ease: ' power1. inOut'})
        
        positionTimeline
            .to(model.position, {x: 1.0, y: 0, z: 0.45, duration: 20, ease: ' power1. inOut'})
            .to(model.position, {x: 0.0, y: 1.0, z: 3.5, duration: 20, ease: ' power1. inOut'})
            .to(model.position, {x: 1.0, y: 0.0, z: 0.0, duration: 20, ease: ' power1. inOut'})
        
        gui.add(model.position, 'x').min(-Math.PI).max(Math.PI).step(.01);
        gui.add(model.position, 'y').min(-Math.PI).max(Math.PI).step(.01);
        gui.add(model.position, 'z').min(-Math.PI).max(Math.PI).step(.01);
        
        terrain = model.children.find(child => child.name === 'terrain');
        terrain.material = terrainMaterial;

        rover = model.children.find(child => child.name === 'rover');
        rover.material = roverMaterial;

        drone = model.children.find(child => child.name === 'drone');
        drone.material = roverMaterial;

        ellen = model.children.find(child => child.name === 'ellen');
        ellen.material = ellenMaterial;
        
        tentacle = model.children.find(child => child.name === 'tentacle');
        tentacle.material = tentacleMaterial;
        
        drone.children.find(child => child.name === 'drone_emissive').material = emissiveMaterial;
        model.children.find(child => child.name === 'emissive').material = emissiveMaterial;

        updateAllMaterials();
        
        const skeleton = new THREE.SkeletonHelper( model );
        skeleton.visible = true;
        scene.add( skeleton );
        
        animations = gltf.animations;
        // mixer = new THREE.AnimationMixer( model );

        // idleAction = mixer.clipAction( animations[ 0 ] );

        // Play animation
        // idleAction.play();
        tick();
    }, event => {
        //loading
    }, event => {
        //error, load fallback image
        console.error('Didnt load model')
    }
)

// Lights
// const ambientLight = new THREE.AmbientLight(0xffffff, 1.7)
// scene.add(ambientLight)

// gui.add(ambientLight, "intensity").min(0.1).max(2).step(.1);

// const directionalLight = new THREE.DirectionalLight(0xffffff, .25)
// scene.add(directionalLight)
// directionalLight.position.set(0, 50, 0);
// directionalLight.castShadow = true;
// directionalLight.shadow.mapSize.width = 512; // default
// directionalLight.shadow.mapSize.height = 512; // default
// directionalLight.shadow.camera.near = 49; 
// directionalLight.shadow.camera.far = 50; 

// gui.add(directionalLight, "intensity").min(0.1).max(2).step(.1);
// const directionalLightCameraHelper = new THREE.CameraHelper(directionalLight.shadow.camera);
// scene.add(directionalLightCameraHelper);

/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth;
    sizes.height = window.innerHeight;

    // Update camera
    camera.aspect = sizes.width / sizes.height;
    camera.updateProjectionMatrix();

    // Update renderer
    renderer.setSize(sizes.width, sizes.height);
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

    // Update effect composer
    effectComposer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    effectComposer.setSize(sizes.width, sizes.height);
})

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100)
camera.position.set(-0.75, 0, 2);
scene.add(camera)

// Controls
// const controls = new OrbitControls(camera, canvas)
// controls.enableDamping = true

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    antialias: true,
    alpha: true
})
renderer.shadowMap.enabled = true
renderer.shadowMap.type = THREE.PCFShadowMap
renderer.physicallyCorrectLights = true
renderer.outputEncoding = THREE.sRGBEncoding
renderer.toneMapping = THREE.ReinhardToneMapping
renderer.toneMappingExposure = 1.5
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

/**
 * Post processing
 */

//Render Target
let RenderTargetClass = null;

if (renderer.getPixelRatio() === 1 && renderer.capabilities.isWebGL2)
{
    RenderTargetClass = THREE.WebGLMultisampleRenderTarget
    console.log('Using WebGLMultisampleRenderTarget');
} else {
    RenderTargetClass = THREE.WebGLRenderTarget
    console.log('Using WebGLRenderTarget')
}

const renderTarget = new RenderTargetClass(
    800,
    600,
    {
        minFilter: THREE.LinearFilter,
        magFilter: THREE.LinearFilter,
        format: THREE.RGBAFormat,
        encoding: THREE.sRGBEncoding,
        // stencilBuffer: false
    }
);

// Composer
const effectComposer = new EffectComposer(renderer, renderTarget);
effectComposer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
effectComposer.setSize(sizes.width, sizes.height);

//Passes
const renderPass = new RenderPass(scene, camera);
effectComposer.addPass(renderPass);

// Unreal bloom pass
const unrealBloomPass = new UnrealBloomPass();
effectComposer.addPass(unrealBloomPass);
unrealBloomPass.strength = 1.0;
unrealBloomPass.radius = 0.1;
unrealBloomPass.threshold = 0.52;

gui.add(unrealBloomPass, 'enabled');
gui.add(unrealBloomPass, 'strength').min(0).max(2).step(0.001);
gui.add(unrealBloomPass, 'radius').min(0).max(2).step(0.001);
gui.add(unrealBloomPass, 'threshold').min(0).max(1).step(0.001);

// Vignette pass
const vignettePass = new ShaderPass(vignetteMaterial);
effectComposer.addPass(vignettePass);

/**
 * Animate
 */

let mouseX = 0;
let mouseY = 0;

let targetX = 0;
let targetY = 0;

const windowHalfX = window.innerWidth / 2;
const windowHalfY = window.innerHeight / 2;

const updateParallax = event => {
    mouseX = (event.clientX - windowHalfX) / windowHalfX;
    mouseY = (event.clientY - windowHalfY) / windowHalfY;
}

document.addEventListener('mousemove', updateParallax);

const updatecube = event => {
    // cube.position.z = window.scrollY * -.01;
}

window.addEventListener('scroll', updatecube);

function moveCamera() {
    const t = document.body.getBoundingClientRect().top;
    // model.rotation.x += t * -0.01;
    // model.rotation.y += t * -0.01;
    // model.rotation.z += t * -0.01;

    // pokeball.rotation.y += 0.01;
    // pokeball.rotation.z += 0.01;

    // camera.position.z = t * -0.01;
    // camera.position.x = t * -0.0002;
    // camera.rotation.y = t * -0.0002;
}

document.body.onscroll = moveCamera;
moveCamera();

const clock = new THREE.Clock()

const tick = () =>
{
    targetX = mouseX * .001;
    targetY = mouseY * .001;

    // let mixerUpdateDelta = clock.getDelta();

    // Update objects
    // model.rotation.y += 0.00005 * elapsedTime

    // model.rotation.y += .01 * (targetX - model.rotation.y);
    // model.rotation.x += .005 * (targetY - model.rotation.x);
    // model.position.z += -.01 * (targetY - model.rotation.x);

    // update animations
    // mixer.update( mixerUpdateDelta );

    // Update Orbital Controls
    // controls.update()

    // Render
    // renderer.render(scene, camera)
    effectComposer.render();

    // Call tick again on the next frame
    window.requestAnimationFrame(tick);
}